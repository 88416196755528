import React from 'react'

import { Tag, TagButton, TagDivider, Tags, XIcon } from '@te-digi/styleguide'

const TagNotificationExample = () => (
  <Tags noMargin>
    <Tag label="Lorem ipsum">
      <TagDivider />
      <TagButton
        hasCloseNotification
        icon={<XIcon />}
      >
        Poista
      </TagButton>
    </Tag>
  </Tags>
)

export { TagNotificationExample }
