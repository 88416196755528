import React from 'react'

import {
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownSection,
  Heading,
  HideIcon,
  InfoCircleIcon,
  Paragraph,
  Tag,
  TagButton,
  TagDivider,
  Text,
  XIcon
} from '@te-digi/styleguide'

const TagDropdownExample = () => (
  <Tag label="Lorem ipsum">
    <TagDivider />
    <Dropdown
      placement="bottom"
      ariaHaspopup="dialog"
      size="lg"
      trigger={<TagButton icon={<InfoCircleIcon />}>Tietoa</TagButton>}
    >
      <DropdownSection>
        <Heading
          size={5}
          noMargin
        >
          <Text color="primary">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit
          </Text>
        </Heading>
        <Paragraph>
          Sed posuere interdum sem. Quisque ligula eros ullamcorper quis,
          lacinia quis facilisis sed sapien.
        </Paragraph>
        <Heading
          size={5}
          noMargin
        >
          <Text color="primary">Mistä avainsana on päätynyt profiiliini?</Text>
        </Heading>
        <Paragraph noMargin>Consectetuer et venenatis eget velit.</Paragraph>
      </DropdownSection>
    </Dropdown>
    <TagDivider />
    <Dropdown
      placement="bottom"
      trigger={<TagButton icon={<XIcon />}>Poista</TagButton>}
    >
      <DropdownActions>
        <DropdownActionsItem
          icon={<HideIcon />}
          onClick={() => alert('Click!')}
          subLabel="Osaamista ei käytetä työpaikkaehdotuksiin, mutta se säilyy edelleen profiilissasi"
        >
          Piilota
        </DropdownActionsItem>
        <DropdownActionsItem
          icon={<XIcon />}
          onClick={() => alert('Click!')}
          subLabel="Osaaminen poistuu profiilistasi"
        >
          Poista
        </DropdownActionsItem>
      </DropdownActions>
    </Dropdown>
  </Tag>
)

export { TagDropdownExample }
