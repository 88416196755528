import React from 'react'

import {
  Help,
  List,
  ListItem,
  Notifications,
  Paragraph,
  Tag,
  TagButton,
  TagDivider,
  TagItem,
  TagLabel,
  Tags
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Link } from '../../components/Link'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import { TagExample1 } from '../../examples/react/TagExample1'
import { TagExample2 } from '../../examples/react/TagExample2'
import { TagDropdownExample } from '../../examples/react/TagDropdownExample'
import { TagNotificationExample } from '../../examples/react/TagNotificationExample'
import { TagSizeExample } from '../../examples/react/TagSizeExample'
import { TagBlockExample } from '../../examples/react/TagBlockExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Tag"
    components={[
      { component: Tags },
      { component: Tag },
      { component: TagButton, restElement: true },
      { component: TagDivider },
      { component: TagItem },
      { component: TagLabel }
    ]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section title="Valinnan tekeminen">
      <Paragraph>
        Oletus <Code>Tag</Code>ia käytetään tilanteissa, jossa komponentti pysyy
        koko ajan näkyvillä ja sen tilaa vaihdellaan aktiivisen ja ei-aktiivisen
        välillä.
      </Paragraph>
      <Help>
        Tagia ei suositella käytettäväksi lomakkeella pakollisten tietojen
        valitsemiseen. Käytä sen sijaan esim.{' '}
        <Code>
          <Link page="CheckboxButton" />
        </Code>
        ia.
      </Help>
      <Playground
        example={TagExample1}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Toimintojen liittäminen">
      <Paragraph>
        <Code>Tag</Code>ille voi liittää haluttuja toimintoja, kuten
        poisto-painikkeen <Code>children</Code>-propin avulla.
      </Paragraph>
      <Paragraph>
        Poistettavaa <Code>Tag</Code>ia käytetään esimerkiksi{' '}
        <Code>Select</Code>istä tai ehdotuksista tehtyjen valintojen
        esittämiseen.
      </Paragraph>

      <Playground
        enableOverflow
        example={TagNotificationExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Paragraph>Liitettyjä toimintoja voi olla myös useampia.</Paragraph>
      <Playground
        enableOverflow
        example={TagExample2}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        enableOverflow
        example={TagDropdownExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Koon määrittäminen">
      <Paragraph>
        Pienikokoinen <Code>Tag</Code> on yhden rivin korkuinen, oletuskokoinen
        näyttää maksimissaan kaksi riviä. Pienikokoista <Code>Tag</Code>ia
        käytetään yleisimmin <Code>Select</Code>istä tehtyjen valintojen
        listaamiseen.
      </Paragraph>
      <Playground
        enableOverflow
        example={TagSizeExample}
        WrapperComponent={FormLayoutWrapper}
      />
      <Paragraph>
        <Code>Tag</Code>:in leveyteen voidaan vaikuttaa <Code>block</Code>
        -propilla.
      </Paragraph>
      <Playground
        enableOverflow
        example={TagBlockExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Tyhjän Tag-listan esittäminen">
      <Paragraph>
        Jos kohta, johon listataan käyttäjän valitsemia <Code>Tag</Code>eja on
        tyhjä, voidaan listan paikalla näyttää <Code>Tags</Code>-komponentti,
        jolle on määritelty <Code>emptyText</Code>.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <Tags
          emptyText="Ei valittuja avainsanoja"
          noMargin
        />
      </Playground>
      <Paragraph>
        Erityistapauksissa <Code>emptyText</Code>in väriksi voidaan{' '}
        <Code>emptyColor</Code>-propilla antaa <Code>danger</Code> korostamaan
        erityisen tärkeää puuttuvaa tietoa ennen kuin yhtään valintaa on tehty.
      </Paragraph>
      <Paragraph>
        Proppia ei suositella käytettäväksi pakollisen tiedon
        validointivirheistä viestimiseen, koska korostus on puhtaasti
        visuaalinen, eikä se ei tee <Code>emptyText</Code>istä semanttisesti
        alertia.
      </Paragraph>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <Tags
          emptyColor="danger"
          emptyText="Ei valittuja avainsanoja"
          noMargin
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>
        <Code>Tag</Code>ia ei suositella käytettäväksi lomakkeella pakollisten
        tietojen valitsemiseen
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <Code>Tag</Code>eillä tehtyjä valintoja ei voi merkitä semanttisesti
          virheelliseksi samoin kuin input-kenttiä (pakolliseksi merkityn tyhjän
          input-kentän <Code>invalid</Code>-attribuutti ilmaistaan
          ruudunlukijalla, mutta <Code>Tag</Code>it tai <Code>Tags</Code> eivät
          ole lomakekekenttiä).
        </ListItem>
        <ListItem>
          Puuttuvista tageista varoittaminen <Code>emptyText</Code>in{' '}
          <Code>danger</Code>-värillä ei tee siitä semanttisesti alertia, eikä
          tieto validointivirheistä näin ollen välity ruudunlukijalle.
        </ListItem>
      </List>
      <Paragraph noMargin>
        Kun <Code>Tag</Code>iin on liitetty poistotoiminnallisuus, poistamisesta
        tulee antaa palaute myös ruudunlukijalle piilotetulla notifikaatiolla.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Notifikaatio voidaan lisätä käyttäen <Code>hasCloseNotification</Code>
          -proppia. Tällöin ilmoitus sisältää <Code>Tag</Code>in labelin ja
          &quot;poistettu&quot;. Vaihtoehtoisesti poistamisesta voi ilmoittaa
          omalla piilotetulla notifikaatiolla (ks.{' '}
          <Code>
            <Link page="Notifications" />
          </Code>
          -komponentin <Code>addNotification</Code>).
        </ListItem>
        <ListItem>
          Kummassakin tapauksessa (oma notifikaatio tai komponenttiin rakennettu
          toiminnallisuus) tulee huomioida, että sivulla tulee olla{' '}
          <Code>
            <Link page="Notifications" />
          </Code>
          -komponentti käytössä.
        </ListItem>
      </List>
      <Paragraph>
        Mikäli <Code>Tag</Code>issa on useampi painettava kohta, kummastakin
        kohdasta pitää selvitä selkeästi, mitä niistä tapahtuu, esimerkiksi:
        &quot;muokkaa&quot; tai &quot;poista&quot;.
      </Paragraph>
    </Section>
    <Notifications />
  </Content>
)

export default Page
