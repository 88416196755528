import React from 'react'

import {
  PenIcon,
  Tag,
  TagButton,
  TagDivider,
  Tags,
  XIcon
} from '@te-digi/styleguide'

const TagExample2 = () => (
  <Tags noMargin>
    <Tag label={'Lorem ipsum'}>
      <TagDivider />
      <TagButton
        icon={<PenIcon />}
        onClick={() => alert('Edit clicked')}
      >
        Muokkaa
      </TagButton>
      <TagDivider />
      <TagButton
        icon={<XIcon />}
        onClick={() => alert('Close clicked')}
      >
        Poista
      </TagButton>
    </Tag>
  </Tags>
)

export { TagExample2 }
