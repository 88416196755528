import React from 'react'

import { Tag, TagButton, TagDivider, Tags, XIcon } from '@te-digi/styleguide'

const TagSizeExample = () => (
  <Tags noMargin>
    <Tag
      label="Pieni tag"
      size="xs"
    >
      <TagDivider />
      <TagButton
        icon={<XIcon />}
        onClick={() => alert('Close clicked')}
      >
        Poista
      </TagButton>
    </Tag>
    <Tag
      label={
        'Suuri tag johon mahtuu paljon tekstiä ja joka rivittyy tarvittaessa kahdelle riville ja katkeaa jos ei mahdu kokonaan'
      }
    >
      {' '}
      <TagDivider />
      <TagButton
        icon={<XIcon />}
        onClick={() => alert('Close clicked')}
      >
        Poista
      </TagButton>
    </Tag>
  </Tags>
)

export { TagSizeExample }
