import React, { useState } from 'react'

import { Tag, Tags } from '@te-digi/styleguide'

const TagExample1 = () => {
  const [isActive1, setIsActive1] = useState(false)
  const [isActive2, setIsActive2] = useState(false)

  return (
    <Tags
      label="Label"
      noMargin
      subLabel="Sub Label"
    >
      <Tag
        active={isActive1}
        label="Vaihtoehto 1"
        onClick={() => setIsActive1(active => !active)}
      />
      <Tag
        color="primary"
        active={isActive2}
        label="Vaihtoehto 2"
        onClick={() => setIsActive2(active => !active)}
      />
    </Tags>
  )
}

export { TagExample1 }
