import React from 'react'

import { Tag, TagButton, TagDivider, XIcon } from '@te-digi/styleguide'

const TagBlockExample = () => (
  <Tag
    block
    label={'Kokoleveä tag'}
  >
    <TagDivider />
    <TagButton
      icon={<XIcon />}
      onClick={() => alert('Close clicked')}
    >
      Poista
    </TagButton>
  </Tag>
)

export { TagBlockExample }
